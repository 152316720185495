<template>
  <div>
    <SettingsWrp />
  </div>
</template>

<script>
    import SettingsWrp from '@/components/Settings/SettingsWrp'


    export default {
        components: {
          SettingsWrp,
        }
    }
</script>

<style scoped>

</style>