<template>
    <div class="settings-info">
        <div class="settings__title">
            <p class="settings__main-title">Settings</p>
        </div>
        <vue-tabs>
                <v-tab title="General">
                    <GeneralSettings />
                </v-tab>
                <v-tab title="Permissions">
                    <PermissionsSettings />
                </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
    import {VueTabs, VTab} from 'vue-nav-tabs'
    import 'vue-nav-tabs/themes/vue-tabs.css'
    import GeneralSettings from '@/components/Settings/GeneralSettings'
    import PermissionsSettings from '@/components/Settings/PermissionsSettings'


    export default {
        components: {
            VueTabs, VTab, PermissionsSettings, GeneralSettings
        }
    }
</script>

<style>
    .settings__main-title{
        padding: 0;
        margin: 0;
    }

    .settings-info{
        position: relative;
        width: 100%;
        max-width: calc(100vw - 263px);
        transition: all .3s ease;
        padding-bottom: 30px;
        height: 85vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .close .settings-info{
        max-width: calc(100vw - 60px);
        transition: all .3s ease;

    }

    .settings__title{
        margin: 20px 0 0 30px;
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        padding: 15px 20px 10px 20px;
        border-radius: 30px 0 0 0;
        background-color: #fff;
        display: inline-block;
        z-index: 5;
    }

    .settings-info .nav-tabs-navigation{
        margin-top: -56px;
        margin-left: 181px;
    }

    .settings-info .nav-tabs-wrapper{
        height: 56px;
    }

    .settings-info .vue-tabs{
        width: 100%;
    }

    .settings-info .vue-tabs .nav-tabs{
        border: none;

    }

    .settings-info .vue-tabs .nav-tabs > li{
        height: 56px;
        box-shadow: 6px -10px 23px -10px rgba(34, 60, 80, 0.05);
    }

    .settings-info .vue-tabs .nav-tabs > li > a{
        line-height: 44px;
        border: none;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
        border-top: 2px solid #fff;
        border-radius: 0;
    }

    .settings-info .vue-tabs .nav-tabs > li.active > a{
        border: none;
        border-top: 2px solid #37F1C5;
        height: 36px;
        margin: 0;
        background: #37F1C51A;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
    }

    .settings-info .vue-tabs .nav-tabs > li.active > a,
    .settings-info .vue-tabs .nav-tabs > li.active > a:hover,
    .settings-info .vue-tabs .nav-tabs > li.active > a:focus{
        height: 34px;
        color: #222222;
        background: #37F1C51A;
        border: none;
        border-top: 2px solid #37F1C51A;
    }


    .settings-info div.vue-tabs .nav-tabs > li.active > a:focus{
        color: #222222;
        height: 34px;
        background: #37F1C51A;
        border: none;
        border-top: 2px solid #37F1C5;
    }

    .settings-info .vue-tabs .nav-tabs > li.active > a{
        border-top: 2px solid #37F1C5;
        height: 34px;
    }
    
    .settings-info .vue-tabs .nav > li > a:hover,
    .settings-info .vue-tabs .nav > li > a:focus{
        background: #37F1C51A;
        border: none;
        height: 34px;
        border-top: 2px solid #37F1C51A;
    }

    .settings-info .vue-tabs .nav-tabs > li{
        background: #fff;
    }

    .settings-info .vue-tabs .nav-tabs > li:last-child,
    .settings-info .vue-tabs .nav-tabs > li:last-child > a{
        border-radius: 0 30px 0 0;
    }

    .settings-info .vue-tabs .nav-tabs > li:last-child > a{
        padding-right: 25px;
    }

    @media(max-width: 800px) {
        .settings-info{
            max-width: calc(100vw - 60px);
        }

        .close .settings-info{
            max-width: calc(100vw - 60px);
        }
    }

    @media(max-width:560px){

        .settings-info{
            margin: 0 15px;
            padding: 10px 0;
        }
        
        .settings__title{
            margin: 0;
        }

        .settings-info{
            max-width: calc(100vw - 90px);
        }

        .close .settings-info{
            max-width: calc(100vw - 90px);
        }

        .settings-info .nav-tabs-navigation {
            margin-left: 151px;
        }

    }

    @media(max-width: 470px){
        .settings__title{
            background: none;
            box-shadow: none;
        }

        .settings-info .nav-tabs-navigation{
            margin-top: 0;
            margin-left: 0;
        }

        .vue-tabs .nav-tabs > li:first-child > a{
            padding-left: 25px;
            border-radius: 30px 0 0 0;
        }
    }

    @media(max-width: 450px){
        .settings-info{
            height: 80vh;
        }
    }

    @media(max-width: 340px){
        .settings-info .vue-tabs .nav-tabs > li > a{
            font-size: 12px;
        }

        .settings-info .vue-tabs .nav-tabs > li:first-child > a{
            padding-left: 10px;
            border-radius: 0;
        }

        .settings-info .vue-tabs .nav-tabs > li:last-child > a{
            padding-right: 10px;
            border-radius: 0;
        }

        .settings-info .vue-tabs .nav-tabs > li:last-child{
            border-radius: 0;
        }

        .settings-info .vue-tabs .nav-tabs > li.active > a{
            font-size: 14px;
        }
    }

</style>