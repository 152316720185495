<template>
    <div class="settings__wrp">
        <div class="settings__item name">
            <h3 class="settings__title">{{ store.currentTitle }}</h3>
            <!-- p class="settings__info">Company logo will appear in shared reports and branded PDF exports. Formats: png, jpg, gif. Max size: 1 MB.</p -->
            <p class="settings__logo">AF</p>
        </div>
        <div class="settings__item workspace">
            <h3 class="settings__title">Workspace name</h3>
                <input class="settings__workspace-input" type="text" v-bind:value="store.currentTitle" @change="changeTitle($event)" placeholder="Workspace name">
        </div>
        <div class="settings__item timesheet">
            <h3 class="settings__title">Timesheet</h3>
            <p class="settings__info">Enter time on tasks and projects using a weekly timesheet view. While activated, project is a required field for the whole workspace.</p>
            <div class="settings__timesheet">
                <input class="timesheet-filter timesheet-filter--active" type="checkbox" :checked="store.current ? store.current.tsa == '1' : false" @change="changeTsa($event)" name="" id="timesheet-active"><label for="timesheet-active" class="timesheet-filter__label timesheet-filter__label--active">Activate timesheet</label>
                <input class="timesheet-filter timesheet-filter--hide" type="checkbox" :checked="store.current ? store.current.ta == '1' : false" @change="changeTa($event)" name="" id="timesheet-hide"><label for="timesheet-hide" class="timesheet-filter__label timesheet-filter__label--hide">Activate tracker</label>
            </div>
        </div>
        <div class="settings__item project">
            <h3 class="settings__title">New projects are by default</h3>
            <p class="settings__info">When you create a project, make it public so it's available to all users, and billable so its time entries are set as billable.</p>
            <div class="settings__project">
                <div class="settings__project-item">
                    <p class="settings__project-input">
                        <input type="radio" id="billVariant1" :checked="store.current ? store.current.pdb == '0' : false" @click="changeBillable(0)" name="bill">
                        <label class="settings__project-label" for="billVariant1">Non-billable</label>
                    </p>
                    <p class="settings__project-input">
                        <input type="radio" id="billVariant2" :checked="store.current ? store.current.pdb == '1' : false" @click="changeBillable(1)" name="bill">
                        <label class="settings__project-label" for="billVariant2">Billable</label>
                    </p>
                </div>
                <div class="settings__project-item">
                    <p class="settings__project-input">
                        <input type="radio" id="privateVariant1" :checked="store.current ? store.current.pdp == '1' : false" @click="changePublic(1)" name="public">
                        <label class="settings__project-label" for="privateVariant1">Public</label>
                    </p>
                    <p class="settings__project-input">
                        <input type="radio" id="privateVariant2" :checked="store.current ? store.current.pdp == '0' : false" @click="changePublic(0)" name="private">
                        <label class="settings__project-label" for="privateVariant2">Private</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="settings__item group">
            <h3 class="settings__title">Group projects by</h3>
            <p class="settings__info">If you don’t have clients or departments, you can change the label to something else.</p>
            <div class="settings__group">
                <v-selectize v-model="groupBy" :options="['Date', 'Status']"/>
            </div>
        </div>
        <div class="settings__item duration">
            <h3 class="settings__title">Duration format</h3>
            <p class="settings__info">Display time in clock format (with or without seconds).</p>
            <div class="settings__group">
                <v-selectize v-model="duration" @change="changeDuration" :options="['Full (hh:mm:ss)', 'Compact (h:mm)', 'Decimal (h.hh)']"/>
            </div>
        </div>
        <div class="settings__item timesheet">
            <h3 class="settings__title">Project favorites</h3>
            <p class="settings__info">Let people mark their most used projects as favorite so they appear at the top of their project list when tracking time.</p>
            <div class="settings__timesheet">
                <input class="timesheet-filter timesheet-filter--fav" :checked="store.current ? store.current.pf == '1' : false" @change="changeProjectFav($event)" type="checkbox" name="" id="timesheet-fav">
                <label for="timesheet-fav" class="timesheet-filter__label timesheet-filter__label--fav">Activate project favorites</label>
            </div>
        </div>
        <div class="settings__item rate">
            <h3 class="settings__title">Workspace billable rate</h3>
            <p class="settings__info">Default value of each billable hour when there’s no user or project hourly rate.</p>
            <div class="settings__rate">
                <input class="settings__workspace-input" v-model="billableRate" type="text" >
                <button class="settings__change-rate" @click="changeBillableRate">Change</button>
            </div>
        </div>
        <div class="settings__item currency">
            <h3 class="settings__title">Currency</h3>
            <p class="settings__info">The currency in which the billable and cost amounts will be displayed in reports.</p>
            <div class="settings__rate">
                <input class="settings__workspace-input" type="text" v-model="currency" placeholder="USD">
            </div>
        </div>
        <EditRateModal v-if="showEditRateModal" @close="showEditRateModal = false"/>
        <div class="bg" v-if="showEditRateModal" @close="showEditRateModal = false"></div>
    </div>
</template>

<script>
    import EditRateModal from '@/components/Modal/EditRateModal'
    import 'selectize/dist/css/selectize.default.css'
    import VSelectize from '@isneezy/vue-selectize'
    import { useWorkspaceStore } from '@/store/WorkspaceStore'


    export default {
        data() {
            const store = useWorkspaceStore()
            return {
                store: store,
                showChangePassword: false,
                showChangeEmail: false,
                groupBy: store.current ? (store.current.pgb == 0 ? 'Date' : 'Status') : 'Date',
                billableRate: store.current ? store.current.br : 0,
                currency: store.current ? store.current.c : '',
                showSelectTimeZone: false,
                duration: this.getDurationFormat(store),
                showEditRateModal: false
            }
        },
        components: {
            VSelectize, EditRateModal
        },
        watch: {
            groupBy(){
                this.store.current.pgb = this.groupBy == 'Date' ? 0 : 1
                this.store.update(this.store.current, { pgb: this.store.current.pgb })
            },
            duration(){
                this.changeDuration()
            },
            currency(){
                this.store.current.c = this.currency
                this.store.update(this.store.current, { c: this.currency})
            }
        },
        methods:{
            changeDuration(){
                let durationFormat = 0
                switch(this.duration){
                    case 'Full (hh:mm:ss)': 
                        durationFormat = 0
                        break
                    case 'Compact (h:mm)': 
                        durationFormat = 1
                        break
                    case 'Decimal (h.hh)': 
                        durationFormat = 2
                        break
                }
                this.store.current.df = durationFormat
                this.store.update(this.store.current, { df: durationFormat })
            },
            getDurationFormat(store){
                let df = 'Full (hh:mm:ss)'
                if ( store.current ){
                    switch(store.current.df){
                        case 0: 
                            df ='Full (hh:mm:ss)'
                            break
                        case 1: 
                            df ='Compact (h:mm)'
                            break
                        case 2: 
                            df ='Decimal (h.hh)'
                            break
                    }
                }
                return df
            },
            changeBillableRate(){
                this.store.current.br = this.billableRate
                this.store.update(this.store.current, { br: this.billableRate})
            },
            changeProjectFav(event){
                this.store.current.pf = event.target.checked ? 1 : 0
                this.store.update(this.store.current, { pf: event.target.checked ? 1 : 0 })
            },
            changeTitle(event){
                this.store.current.t = event.target.value
                this.store.update(this.store.current, { t: event.target.value })
            },
            changeTsa(event){
                this.store.current.tsa = event.target.checked ? 1 : 0
                this.store.update(this.store.current, { tsa: event.target.checked ? 1 : 0 })
            },
            changeTa(event){
                this.store.current.ta = event.target.checked ? 1 : 0
                this.store.update(this.store.current, { ta: event.target.checked ? 1 : 0 })
            },
            changeBillable(billable){
                this.store.current.pdb = billable
                this.store.update(this.store.current, { pdb: billable })
            },
            changePublic(isPublic){
                this.store.current.pdp = isPublic
                this.store.update(this.store.current, { pdp: isPublic })
            }
        },
        created(){
            if ( this.store.current )
                this.store.getWorkspaceDetails(this.store.currentUk)
        }
    }
</script>

<style scoped>
    h3,
    p{
        padding: 0;
        margin: 0;
    }

    input{
        outline: none;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .settings__wrp{
        background-color: #fff;
        height: 100%;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
        padding: 0 25px;
        box-sizing: border-box;
        margin: 0 30px;
    }

    .settings__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .settings__item{
        border-bottom: 2px solid #F2F3F6;
        padding: 20px 0;
    }

    .workspace{
        display: flex;
        flex-direction: column;
    }

    .settings__info{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
    }

    .settings__logo{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #E058B2;
        text-align: center;
        line-height: 100px;
        font-size: 38px;
        font-weight: 600;
        color: #fff;
        margin-top: 20px;
    }
    
    .settings__workspace-input{
        height: 42px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 15px;
        margin-right: 10px;
        font-family: Montserrat;
        width: 600px;
    }

    .settings__timesheet{
        display: flex;
        flex-direction: column;
    }

    .settings__timesheet input{
        display: none;
    }

    .settings__timesheet label{
        position: relative;
        width: 150px;
        height: 20px;
        margin: 15px 0 0 0;
        padding-left: 50px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        cursor: pointer;
    }

    .settings__timesheet label:after{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/off.png');
        top: 0;
        left: 0;
    }

    .timesheet-filter--active:checked ~ .timesheet-filter__label--active:after{
        background-image: url('../../assets/img/on.png');
    }

    .timesheet-filter--hide:checked ~ .timesheet-filter__label--hide:after{
        background-image: url('../../assets/img/on.png');
    }

    .settings__project-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
    }

    input[type="radio"]{
        display: none;
    }


    .settings__project-label:before{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/radio_pas.png');
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: left;
    }

    input[type="radio"]:checked ~ .settings__project-label:before{
        background-image: url('../../assets/img/radio_act.png');
    }

    .settings__project-item{
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        width: 260px;
    }

    .settings__project-item:first-child{
        border-bottom: 2px solid #F2F3F6;
    }

    .settings__project-input{
        width: 130px;
    }

    .settings__project{
        margin-top: 10px;
    }

    .settings__group{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .timesheet-filter--fav:checked ~ .timesheet-filter__label--fav:after{
        background-image: url('../../assets/img/on.png');
    }

    .settings__timesheet .timesheet-filter__label--fav{
        width: 300px;
    }

    .settings__rate{
        margin-top: 10px;
    }

    .settings__rate .settings__workspace-input{
        width: 120px;
    }

    .settings__change-rate{
        height: 42px;
        width: 115px;
        border: 2px solid #9CEDFF;
        border-radius: 30px;
        text-align: center;
        background: #9CEDFF1A;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .currency{
        border-bottom: 0;
        padding-bottom: 30px;
    }

    @media(max-width: 980px){
        .settings__workspace-input{
            width: 100%;
        }
    }

    @media(max-width:560px){
        .settings__wrp{
            padding: 0 15px;
            margin: 0;
        }

        .settings__project-item{
            flex-direction: column;
            width: auto;
        }

        .settings__timesheet .timesheet-filter__label--fav{
            width: auto;
        }

        .settings__change-rate{
            margin-top: 5px;
        }

        .settings__workspace-input{
            margin-top: 5px;
        }

        .settings__timesheet label{
            width: auto;
        }
    }


</style>