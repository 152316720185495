<template>
    <div class="settings__wrp">
        <div class="settings__item project">
            <h3 class="settings__title">Who can see billable rates and amounts</h3>
            <p class="settings__info">Hide billable rates and amounts from regular users so only admins can see them.</p>
            <div class="settings__rates">
                <div class="settings__rates-item">
                    <p class="settings__rates-input">
                        <input type="radio" id="admins" :checked="store.current ? store.current.usbr == '0' : false" @change="changeUserCanSeeBillable(0)" name="billableRate">
                        <label class="settings__rates-label" for="admins">Admins</label>
                    </p>
                    <p class="settings__rates-input">
                        <input type="radio" id="everyone"  :checked="store.current ? store.current.usbr == '1' : false" @change="changeUserCanSeeBillable(1)" name="billableRate">
                        <label class="settings__rates-label" for="everyone">Everyone</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="settings__item create">
            <h3 class="settings__title">Regular users can create</h3>
            <p class="settings__info">Control how much freedom your team has when categorizing their tracked time.</p>
            <div class="settings__create">
                <p class="create__item">
                    <input class="create__input" type="checkbox" :checked="store.current ? store.current.ucp == '1' : false" @change="changeUserCreatesProjects($event)"  name="" id="projects">
                    <label class="create__label" for="projects">Projects and clients</label>
                </p>
                <p class="create__item">
                    <input class="create__input" type="checkbox" :checked="store.current ? store.current.uct == '1' : false" @change="changeUserCreatesTags($event)" name="" id="tags">
                    <label class="create__label" for="tags">Tags</label>
                </p>
                <p class="create__item">
                    <input class="create__input" type="checkbox" :checked="store.current ? store.current.uctsk == '1' : false" @change="changeUserCreatesTasks($event)" name="" id="tasks">
                    <label class="create__label" for="tasks">Tasks</label>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { useWorkspaceStore } from '@/store/WorkspaceStore'

    export default {
        data() {
            const store = useWorkspaceStore()
            return {
                store: store
            }
        },
        components: {
        },
        methods:{
            changeUserCanSeeBillable(seeBillable){
                this.store.current.usbr = seeBillable
                this.store.update(this.store.current, { usbr: seeBillable })
            },
            changeUserCreatesProjects(event){
                this.store.current.ucp = event.target.checked ? 1 : 0
                this.store.update(this.store.current, { ucp: event.target.checked ? 1 : 0 })
            },
            changeUserCreatesTags(event){
                this.store.current.uct = event.target.checked ? 1 : 0
                this.store.update(this.store.current, { uct: event.target.checked ? 1 : 0 })
            },
            changeUserCreatesTasks(event){
                this.store.current.uctsk = event.target.checked ? 1 : 0
                this.store.update(this.store.current, { uctsk: event.target.checked ? 1 : 0 })
            },
        }
    }
</script>

<style scoped>
    h3,
    p{
        padding: 0;
        margin: 0;
    }

    input{
        outline: none;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .settings__wrp{
        background-color: #fff;
        height: 100%;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
        padding: 0 25px;
        box-sizing: border-box;
        margin: 0 30px;
    }

    .settings__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .settings__item{
        border-bottom: 2px solid #F2F3F6;
        padding: 20px 0;
    }

    .settings__info{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
    }

    .settings__rates-input:first-child{
        margin-right: 20px;
    }

    .settings__rates-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
    }

    input[type="radio"]{
        display: none;
    }

    .settings__rates-label:before{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/radio_pas.png');
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: left;
    }

    input[type="radio"]:checked ~ .settings__rates-label:before{
        background-image: url('../../assets/img/radio_act.png');
    }

    .settings__rates-item{
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        width: 260px;
    }

    .settings__rates{
        margin-top: 10px;
    }

    .settings__create{
        display: flex;
        flex-direction: row;
    }

    .create__item{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-right: 20px;
    }

    .create__input{
        display: none;
    }

    .create__label{
        position: relative;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        cursor: pointer;
    }

    .create__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0;
        left: 0;
    }

    .create__input:checked ~ .create__label:before,
    .create__input:disabled ~ .create__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .create__input:disabled ~ .create__label{
        color: #BABCBF;
    }

    .create__label span{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        padding-top: 3px;
    }

    .create{
        border-bottom: 0;
        padding-bottom: 30px;
    }

        @media(max-width:560px){
        .settings__wrp{
            padding: 0 15px;
            margin: 0;
        }

        .settings__create{
            display: flex;
            flex-direction: column;
        }

        .settings__rates-item{
            display: flex;
            flex-direction: column;
        }

        .settings__rates-input{
            padding: 5px 0;
        }
    }
/*

    @media(max-width: 450px){
        .settings__change-logo{
            flex-direction: column
        }
        .settings__input{
            width: 100%;
        }


        .settings__format{
            flex-wrap: wrap;
        }

        .input__logo-button{
            margin-top: 10px;
        }

        .settings__p:first-child{
            margin-right: 0;
        }
    } */

    

</style>